import request from "@/api/config.js";

export function updateCompany(id, data) {
  return request({
    url: `company/${id}`,
    method: "put",
    data,
  });
}

export function findCompanyById(id) {
  return request({
    url: `company/${id}`,
    method: "get",
  });
}

export function getStudentsList() {
  return request({
    timeout: 15000,
    url: `company/getStudents/List`,
    method: "get",
  });
}

export function getAll() {
  return request({
    url: "company",
    method: "get",
  });
}

export function downloadFileWithStudents(data) {
  return request({
    url: "company/get/students/file/",
    method: "post",
    data,
  });
}
